import { Container, H2, Paragraph, YStack } from '@holo/ui';
import { useMedia } from '@holo/ui/hooks';
import Image from 'next/image';
import cropPlanning from 'public/img/crop-planning.gif';

const Section6 = () => {
  const media = useMedia();

  return (
    <YStack backgroundColor="$accentPale">
      <Container>
        <YStack $sm={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14" id="crop-planner">
          <YStack
            className={media.sm ? 'flex-order-2' : 'flex-order-1'}
            flex={1}
            $sm={{ flexBasis: 0, justifyContent: 'center' }}
          >
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              AI-powered crop planning
            </H2>

            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              We are developing the world's first AI-powered crop planner that will generate crop plans optimized to
              meet your sales forecast and maximize your yields and soil health. The AI model will run optimization
              based on your sales forecast and historical data for plantings, inputs, yields, and soil tests. The
              platform will connect to online weather services, in-field weather stations, soil sensors, and irrigation
              systems to run optimization based on growing degree days and precipitation data. The model will also
              maximize resource usage, such as seeding houses, fields, high/low tunnels, equipment, and staff
              availability.
            </Paragraph>
          </YStack>

          <YStack className={media.sm ? 'flex-order-1' : 'flex-order-2'} flex={1} $sm={{ flexBasis: 0 }}>
            <Image
              src={cropPlanning}
              priority
              loading="eager"
              alt="Graphic of crop planning"
              style={{
                width: 'auto',
                height: 'auto',
              }}
            />
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section6;
